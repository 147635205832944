export const breakpoint = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1600,
};

export const mq = {
  xs: `@media (min-width: ${breakpoint.xs}px)`,
  sm: `@media (min-width: ${breakpoint.sm}px)`,
  md: `@media (min-width: ${breakpoint.md}px)`,
  lg: `@media (min-width: ${breakpoint.lg}px)`,
  xl: `@media (min-width: ${breakpoint.xl}px)`,
};
