import axios, { AxiosError } from 'axios';
import { notification } from 'antd';

export const handleError = (error: any) => {
  if (axios.isAxiosError(error)) {
    switch (error.code) {
      case AxiosError.ECONNABORTED:
        return;
    }
  }

  console.error(error);

  notification.error({
    message: 'Sistemos klaida',
    description:
      error instanceof AxiosError
        ? `${error.message} (${error.response?.statusText})`
        : `${error}`,
    duration: 30,
  });
};
