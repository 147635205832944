import { axiosInstance } from 'constants/axios-instance';
import { Session } from 'models/session';
import { SessionCreate } from 'models/session-create';

export const getCurrentSession = () =>
  axiosInstance.get<Session>('/session').then((response) => response.data);

export const createSession = (sessionCreate: SessionCreate) =>
  axiosInstance
    .post<void>('/session', sessionCreate)
    .then((response) => response.data);

export const deleteSession = () =>
  axiosInstance.delete<void>('/session').then((response) => response.data);
