import { Params } from 'react-router-dom';

import { AppConfig } from 'constants/app-config';

import { generatePathWithQuery } from './generate-path-with-query';

export const generatePublicPathWithQuery = (
  path: string,
  params?: Params,
  query?: Record<string, string | string[] | null>
) => {
  const url = generatePathWithQuery(path, params, query);

  return AppConfig.publicUrl ? `${AppConfig.publicUrl}${url}` : url;
};
