import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Spin } from 'antd';

import { appRouter } from 'app-router';

import 'config';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Spin />}>
      <RouterProvider router={appRouter} />
    </Suspense>
  </React.StrictMode>
);
