import React from 'react';
import { Session } from 'models/session';

interface AppContextValue {
  session: Session;
  login: (phoneNumber: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const AppContext = React.createContext<AppContextValue>({
  session: {
    user: null,
  },
  login: async () => {},
  logout: async () => {},
});
